<template>
  <div>
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
      "
    >
      <h1 style="font-size: 24px; width: 100%; text-align: center">
        Объявления
      </h1>

      <div v-if="selectedClass.classID === 'admin'" style="width: 800px">
        Создание объявления
        <div style="display: flex; align-items: flex-start; flex-wrap: wrap">
          <div style="width: 300px">
            Заголовок:
            <input class="form-control" v-model="title" type="text" />
          </div>
          <div style="margin-left: 50px; width: 300px">
            Ссылка на материалы:
            <input v-model="link" class="form-control" type="text" />
          </div>
          <div style="width: 700px">
            Текст:
            <textarea
              v-model="text"
              class="form-control"
              rows="3"
              type="text"
            ></textarea>
          </div>
        </div>
        <div>
          <div class="form-check form-switch">
            <input
              v-model="privated"
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault"
              >Объявление приватное? (текст и ссылки не будут отображаться на
              панели при входе в систему)</label
            >
          </div>
        </div>
        <div>
          <button class="btn btn-success" @click="createAD()">
            СОЗДАТЬ ОБЪЯВЛЕНИЕ
          </button>
        </div>
      </div>
    </div>
    <div
      style="
        margin-top: 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
      "
    >
      <div
        v-for="(post, index) in posts"
        :key="index"
        id="post"
        style="display: flex; align-items: baseline"
      >
        <div style="width: 100%; border-radius: 10px">
          <div
            style="
              font-size: 17px;
              width: auto;
              font-weight: bold;
              padding-left: 10px;
              border-radius: 10px;
              border-bottom-left-radius: 0px 0px;
              border-bottom-right-radius: 0px 0px;
            "
          >
            {{ post.title }}
          </div>

          <div style="font-size: 14px; font-weight: normal; padding-left: 15px">
            {{ post.text }} <br />
            <a
              style="color: blue"
              v-if="post.link"
              target="_blank"
              :href="post.link"
              >Ссылка--></a
            >
            <div v-if="post.privated" style="color: red">Приватный</div>
            <div v-if="selectedClass.classID === 'admin'">
              <button class="btn btn-danger" @click="deleteAD(post)">
                Удалить
              </button>
            </div>
            <div style="float: right; padding-right: 10px">
              {{ new Date(post.createdAt).toLocaleDateString() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
export default {
  props: {
    selectedClass: Object,
  },
  data() {
    return {
      posts: [],
      title: "",
      text: "",
      privated: false,
      link: "",
    };
  },
  methods: {
    createAD() {
      console.log("123123123123");
      let data = {
        title: this.title,
        text: this.text,
        privated: this.privated,
      };
      if (this.link != "") {
        data.link = this.link;
      }

      TutorialDataService.createAD(data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          this.getAD();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    getAD() {
      TutorialDataService.getAD()
        .then((response) => {
          var a = new Array();
          a = Object.values(response.data);

          this.posts = a;
          this.posts.sort((a, b) =>
            new Date(a.createAD) < new Date(b.createAD) ? 1 : -1
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteAD(post) {
      TutorialDataService.deleteAD(post._id)
        .then(() => {
          this.getAD();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getAD();
  },
};
</script>

<style lang="scss" scoped>
#post {
  background-color: rgb(213, 238, 255);
  width: 55%;
  height: auto;
  margin-bottom: 5px;
  border-radius: 10px;
}
</style>
